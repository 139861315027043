var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "", "pa-0": "" } },
    [
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c(
                "v-container",
                { attrs: { "py-4": "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { row: "", wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", lg10: "", xl9: "" } },
                        [
                          _c(
                            "v-layout",
                            { attrs: { row: "", wrap: "", "-mx-3": "" } },
                            [
                              _c(
                                "v-flex",
                                {
                                  key: _vm.partnerKey,
                                  attrs: {
                                    xs12: "",
                                    md6: "",
                                    "px-3": "",
                                    "py-2": ""
                                  }
                                },
                                [
                                  _vm.partner &&
                                  _vm.partner.type === "COBRANDED"
                                    ? [
                                        _c("div", {
                                          staticClass: "headline mb-2",
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.$t(
                                                "public.landing.title.presented_by.partner",
                                                {
                                                  partner:
                                                    _vm.partner.organization
                                                      .name[_vm.locale]
                                                }
                                              )
                                            )
                                          }
                                        }),
                                        _c(
                                          "h1",
                                          {
                                            staticClass:
                                              "display-2 StarlingDarkGrey--text mb-3"
                                          },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "public.landing.title.presented_by.starling"
                                                  )
                                                ) +
                                                "\n                  "
                                            )
                                          ]
                                        )
                                      ]
                                    : [
                                        _c(
                                          "h1",
                                          { staticClass: "display-2 mb-3" },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "public.landing.title.default"
                                                  )
                                                ) +
                                                "\n                  "
                                            )
                                          ]
                                        )
                                      ],
                                  _vm.partner.content &&
                                  _vm.partner.content["PITCH"] &&
                                  _vm.partner.content["PITCH"][_vm.locale]
                                    ? _c(
                                        "p",
                                        { staticClass: "starling-body" },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.partner.content["PITCH"][
                                                  _vm.locale
                                                ]
                                              ) +
                                              "\n                "
                                          )
                                        ]
                                      )
                                    : _c(
                                        "p",
                                        { staticClass: "starling-body" },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.$t(
                                                  "public.landing.content.introduction"
                                                )
                                              ) +
                                              "\n                "
                                          )
                                        ]
                                      )
                                ],
                                2
                              ),
                              _c(
                                "v-flex",
                                {
                                  attrs: {
                                    xs12: "",
                                    md6: "",
                                    "px-3": "",
                                    "py-2": ""
                                  }
                                },
                                [
                                  _c(
                                    "v-card",
                                    {
                                      staticClass:
                                        "presentation-action-card py-5"
                                    },
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "headline text-center StarlingDarkGrey--text mb-3"
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.$t(
                                                  "public.landing.content.ready_to_get_started"
                                                )
                                              ) +
                                              "\n                  "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "text-center mb-2" },
                                        [
                                          _c(
                                            "primary-button",
                                            {
                                              attrs: {
                                                id: "register-button",
                                                large: ""
                                              },
                                              on: { click: _vm.toRegistration }
                                            },
                                            [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "public.landing.buttons.register"
                                                    )
                                                  ) +
                                                  "\n                    "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "text-center" },
                                        [
                                          _c(
                                            "secondary-button",
                                            {
                                              attrs: {
                                                id: "sign-in-button",
                                                large: ""
                                              },
                                              on: { click: _vm.toSignIn }
                                            },
                                            [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "public.landing.buttons.sign_in"
                                                    )
                                                  ) +
                                                  "\n                    "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-flex",
            { staticClass: "presentation-bg", attrs: { xs12: "" } },
            [
              _c(
                "v-container",
                { attrs: { "py-4": "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { row: "", wrap: "" } },
                    [
                      _c("v-flex", { attrs: { xs12: "", lg10: "", xl9: "" } }, [
                        _c("h2", { staticClass: "starling-h2" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("public.landing.content.how_it_works")
                            )
                          )
                        ])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-layout",
                    { attrs: { row: "", wrap: "", "pt-3": "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", lg10: "", xl9: "" } },
                        [
                          _c(
                            "v-layout",
                            { attrs: { row: "", wrap: "", "-my-2": "" } },
                            [
                              _c(
                                "v-flex",
                                {
                                  attrs: {
                                    xs12: "",
                                    md4: "",
                                    "pa-2": "",
                                    "order-xs1": "",
                                    "order-md1": ""
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "number-icon mb-2" },
                                    [_vm._v("1")]
                                  ),
                                  _c(
                                    "h4",
                                    { staticClass: "starling-h4 pb-3" },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.$t(
                                              "public.landing.content.check_up.title"
                                            )
                                          ) +
                                          "\n                "
                                      )
                                    ]
                                  ),
                                  _c("p", { staticClass: "starling-body" }, [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm.$t(
                                            "public.landing.content.check_up.description"
                                          )
                                        ) +
                                        "\n                "
                                    )
                                  ])
                                ]
                              ),
                              _c(
                                "v-flex",
                                {
                                  attrs: {
                                    xs12: "",
                                    md4: "",
                                    "pa-2": "",
                                    "order-xs3": "",
                                    "order-md2": ""
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "number-icon mb-2" },
                                    [_vm._v("2")]
                                  ),
                                  _c(
                                    "h4",
                                    { staticClass: "starling-h4 pb-3" },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.$t(
                                              "public.landing.content.build_skills.title"
                                            )
                                          )
                                      )
                                    ]
                                  ),
                                  _c("p", { staticClass: "starling-body" }, [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm.$t(
                                            "public.landing.content.build_skills.description"
                                          )
                                        ) +
                                        "\n                "
                                    )
                                  ])
                                ]
                              ),
                              _c(
                                "v-flex",
                                {
                                  attrs: {
                                    xs12: "",
                                    md4: "",
                                    "pa-2": "",
                                    "order-xs5": "",
                                    "order-md3": ""
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "number-icon mb-2" },
                                    [_vm._v("3")]
                                  ),
                                  _c(
                                    "h4",
                                    { staticClass: "starling-h4 pb-3" },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.$t(
                                              "public.landing.content.connect.title"
                                            )
                                          ) +
                                          "\n                "
                                      )
                                    ]
                                  ),
                                  _c("p", { staticClass: "starling-body" }, [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm.$t(
                                            "public.landing.content.connect.description"
                                          )
                                        ) +
                                        "\n                "
                                    )
                                  ])
                                ]
                              ),
                              _c(
                                "v-flex",
                                {
                                  attrs: {
                                    xs11: "",
                                    md4: "",
                                    "pa-2": "",
                                    "order-xs2": "",
                                    "order-md4": ""
                                  }
                                },
                                [
                                  _c(
                                    "v-img",
                                    { attrs: { contain: "" } },
                                    [
                                      _c("landing-checkup-image", {
                                        staticStyle: {
                                          width: "100%",
                                          height: "100%"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                {
                                  attrs: {
                                    xs11: "",
                                    md4: "",
                                    "pa-2": "",
                                    "order-xs4": "",
                                    "order-md5": ""
                                  }
                                },
                                [
                                  _c(
                                    "v-img",
                                    { attrs: { contain: "" } },
                                    [
                                      _c("landing-build-skills-image", {
                                        staticStyle: {
                                          width: "100%",
                                          height: "100%"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                {
                                  attrs: {
                                    xs11: "",
                                    md4: "",
                                    "pa-2": "",
                                    "order-xs6": "",
                                    "order-md6": ""
                                  }
                                },
                                [
                                  _c(
                                    "v-img",
                                    { attrs: { contain: "" } },
                                    [
                                      _c("landing-connect-image", {
                                        staticStyle: {
                                          width: "100%",
                                          height: "100%"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c(
                "v-container",
                { attrs: { "py-4": "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { row: "", wrap: "" } },
                    [
                      _c("v-flex", { attrs: { xs12: "", lg10: "", xl9: "" } }, [
                        _c(
                          "h2",
                          { staticClass: "starling-h2 text-center mb-2" },
                          [
                            _c(
                              "a",
                              {
                                attrs: {
                                  target: "_blank",
                                  href: _vm.$t(
                                    "public.landing.content.privacy_security.link"
                                  )
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "public.landing.content.privacy_security.title"
                                    )
                                  )
                                )
                              ]
                            )
                          ]
                        ),
                        _c("p", { staticClass: "starling-body text-center" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.$t(
                                  "public.landing.content.privacy_security.description"
                                )
                              ) +
                              "\n            "
                          )
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }