<template>
  <v-container fluid pa-0>
    <v-layout row wrap>
      <v-flex xs12>
        <v-container py-4>
          <v-layout row wrap>
            <v-flex xs12 lg10 xl9>
              <v-layout row wrap -mx-3>
                <v-flex xs12 md6 px-3 py-2 :key="partnerKey">
                  <template v-if="partner && partner.type === 'COBRANDED'">
                    <div class="headline mb-2" v-html="$t('public.landing.title.presented_by.partner', {partner : partner.organization.name[locale]})"></div>
                    <h1 class="display-2 StarlingDarkGrey--text mb-3">
                      {{ $t('public.landing.title.presented_by.starling') }}
                    </h1>
                  </template>
                  <template v-else>
                    <h1 class="display-2 mb-3">
                      {{ $t('public.landing.title.default') }}
                    </h1>
                  </template>
                  <p class="starling-body" v-if="partner.content && partner.content['PITCH'] && partner.content['PITCH'][locale]">
                    {{ partner.content['PITCH'][locale] }}
                  </p>
                  <p class="starling-body" v-else>
                    {{ $t('public.landing.content.introduction') }}
                  </p>
                </v-flex>
                <v-flex xs12 md6 px-3 py-2>
                  <v-card class="presentation-action-card py-5">
                    <p class="headline text-center StarlingDarkGrey--text mb-3">
                      {{ $t('public.landing.content.ready_to_get_started') }}
                    </p>
                    <div class="text-center mb-2">
                      <primary-button id="register-button" @click="toRegistration" large>
                        {{ $t('public.landing.buttons.register') }}
                      </primary-button>
                    </div>
                    <div class="text-center">
                      <secondary-button id="sign-in-button" @click="toSignIn" large>
                        {{ $t('public.landing.buttons.sign_in') }}
                      </secondary-button>
                    </div>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-container>
      </v-flex>
      <v-flex xs12 class="presentation-bg">
        <v-container py-4>
          <v-layout row wrap>
            <v-flex xs12 lg10 xl9>
              <h2 class="starling-h2">{{ $t('public.landing.content.how_it_works') }}</h2>
            </v-flex>
          </v-layout>
          <v-layout row wrap pt-3>
            <v-flex xs12 lg10 xl9>
              <v-layout row wrap -my-2>
                <v-flex xs12 md4 pa-2 order-xs1 order-md1>
                  <div class="number-icon mb-2">1</div>
                  <h4 class="starling-h4 pb-3">
                    {{ $t('public.landing.content.check_up.title') }}
                  </h4>
                  <p class="starling-body">
                    {{ $t('public.landing.content.check_up.description') }}
                  </p>
                </v-flex>
                <v-flex xs12 md4 pa-2 order-xs3 order-md2>
                  <div class="number-icon mb-2">2</div>
                  <h4 class="starling-h4 pb-3">
                    {{ $t('public.landing.content.build_skills.title') }}</h4>
                  <p class="starling-body">
                    {{ $t('public.landing.content.build_skills.description') }}
                  </p>
                </v-flex>
                <v-flex xs12 md4 pa-2 order-xs5 order-md3>
                  <div class="number-icon mb-2">3</div>
                  <h4 class="starling-h4 pb-3">
                    {{ $t('public.landing.content.connect.title') }}
                  </h4>
                  <p class="starling-body">
                    {{ $t('public.landing.content.connect.description') }}
                  </p>
                </v-flex>
                <v-flex xs11 md4 pa-2 order-xs2 order-md4>
                  <v-img contain>
                    <landing-checkup-image style="width:100%;height:100%;"/>
                  </v-img>
                </v-flex>
                <v-flex xs11 md4 pa-2 order-xs4 order-md5>
                  <v-img contain>
                    <landing-build-skills-image style="width:100%;height:100%;"/>
                  </v-img>
                </v-flex>
                <v-flex xs11 md4 pa-2 order-xs6 order-md6>
                  <v-img contain>
                    <landing-connect-image style="width:100%;height:100%;"/>
                  </v-img>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-container>
      </v-flex>
      <v-flex xs12>
        <v-container py-4>
          <v-layout row wrap>
            <v-flex xs12 lg10 xl9>
              <h2 class="starling-h2 text-center mb-2">
                <a target="_blank" :href="$t('public.landing.content.privacy_security.link')">{{ $t('public.landing.content.privacy_security.title') }}</a>
              </h2>
              <p class="starling-body text-center">
                {{ $t('public.landing.content.privacy_security.description') }}
              </p>
            </v-flex>
          </v-layout>
        </v-container>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>

import { mapState } from 'vuex';
import LandingCheckupImage from '@/views/landing/images/landing-checkup-image';
import LandingBuildSkillsImage from '@/views/landing/images/landing-build-skills-image';
import LandingConnectImage from '@/views/landing/images/landing-connect-image';
import PrimaryButton from '@/views/components/button/primary-button';
import SecondaryButton from '@/views/components/button/secondary-button';

export default {
  name: 'presentation',
  components: { SecondaryButton, PrimaryButton, LandingConnectImage, LandingBuildSkillsImage, LandingCheckupImage },
  props: [ 'elevation', 'cardHeight', 'cardStyles' ],
  data() {
    return { partnerKey: 0 };
  },
  computed: {
    ...mapState({
      partner: state => {
        return state.identity;
      },
      locale() {
        return localStorage.getItem('currLang');
      },
    }),
  },
  watch: {
    partner() {
      this.partnerKey++;
    },
    locale() {
      this.partnerKey++;
    },
  },
  methods: {
    toRegistration() {
      this.$router.push({ name: 'sign_up' });
    },
    toSignIn() {
      this.$router.push({ name: 'sign_in' });
    },
  },
};
</script>

<style scoped>
.number-icon {
  border-radius: 50%;
  background-color: var(--StarlingPrimary2);
  width: 40px;
  height: 40px;
  color: white;
  font-size: 1.2em;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}

.presentation-action-card {
  width:100%;
  height:100%;
  border-radius:15px;
  background-image: url('/svg/get-started-card-background.svg');
  background-repeat: no-repeat;
  background-size: cover;
}

.presentation-bg {
  background: url('/svg/landscape-background.svg') bottom center no-repeat, linear-gradient(180deg, #DCFCF8 0%, #FFFFFF 100%);
  background-size: 100%;
  border-top:lightgray solid 1px;
  border-bottom:lightgray solid 1px;
}
</style>
